import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Typography as MuiTypography,
  Box,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { spacing, display } from "@mui/system";
import InfoBadge from "../components/InfoBadge";
import SaleTabs from "../components/SaleTabs/SaleTabs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTheme } from "@mui/material/styles";
import AlertDialog from "../../components/modal/AlertDialog/AlertDialog";
import {
  closeSaleAction,
  fetchSaleDetails,
  processSaleAction,
} from "../../redux/slices/actions/salesActions";
import { getAgent } from "../../redux/slices/agentSlice";
import useAuth from "../../hooks/useAuth";
import SendMailModal from "./SendMailModal";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(display);
const HeaderBox = styled(Box)`
  background-color: ${(props) => props.theme.card.headerBackground};
  margin: 0;
  height: 84px;
  align-items: center;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
`;

const formatStatus = (str: string) => {
  if (!str) return str;

  // Делаем первую букву заглавной
  const result = str.charAt(0).toUpperCase() + str.slice(1);

  // Добавляем пробелы перед каждой заглавной буквой, начиная со второй
  return result.split("").reduce((acc, char) => {
    if (char === char.toUpperCase() && char !== char.toLowerCase()) {
      // Если символ является заглавной буквой
      return `${acc} ${char}`;
    } else {
      return acc + char;
    }
  }, "");
};

function InvoiceDetails() {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams(); // Ensure id is string
  const { saleDetails, loading, error } = useSelector(
    (state: RootState) => state.sales
  );
  const {
    agentDetails,
    loading: agentLoading,
    error: agentError,
  } = useSelector((state: RootState) => state.agent);
  const { role } = useAuth();
  const [success, setSuccess] = useState<boolean>(false);
  const [showIsPaused, setShowIsPaused] = useState<boolean | undefined>(false);
  const [isSendMailModalOpen, setIsSendMailModalOpen] = useState(false); // State to manage modal visibility

  useEffect(() => {
    if (id) {
      dispatch(fetchSaleDetails(Number(id)));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (saleDetails.createdBy) {
      dispatch(getAgent(saleDetails.createdBy));
    }
  }, [saleDetails.createdBy, dispatch]);
  const handleSendMailClick = () => {
    setIsSendMailModalOpen(true);
  };

  const handleCloseSendMailModal = () => {
    setIsSendMailModalOpen(false);
  };

  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading || agentLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet title="Invoice Details" />
      <HeaderBox>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>
            <Typography variant="h3" gutterBottom display="inline">
              Sale #{id}
            </Typography>
            {saleDetails.status === "approved" && (
              <Button
                sx={{ marginLeft: 4 }}
                variant={"contained"}
                onClick={handleSendMailClick}
              >
                Send Mail
              </Button>
            )}
            {saleDetails.status === "inProgress" &&
              (role === "manager" || role === "ticketing") && (
                <Button
                  sx={{ marginLeft: 12 }}
                  variant={"contained"}
                  onClick={() =>
                    dispatch(processSaleAction({ id: Number(id) }))
                  }
                >
                  Process sale
                </Button>
              )}
            {saleDetails.status === "inApproving" && (
              <Button
                sx={{ marginLeft: 12 }}
                variant={"contained"}
                onClick={() => dispatch(closeSaleAction({ id: Number(id) }))}
              >
                Close sale
              </Button>
            )}
          </Grid>

          {saleDetails.isPaused && (
            <Grid item display={"flex"} alignItems={"center"}>
              <WarningAmberIcon fontSize={"large"} color={"warning"} />
              <MuiTypography
                sx={{
                  color: theme.palette.warning.main,
                  marginBottom: 0,
                  marginTop: 1,
                  marginLeft: 2,
                }}
                variant="h3"
                gutterBottom
                display="inline"
              >
                Is Paused
              </MuiTypography>
            </Grid>
          )}
          <AlertDialog />
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="medium" />}
              aria-label="Breadcrumb"
              mt={2}
            >
              <Link
                sx={{ fontWeight: 600, fontSize: 14 }}
                component={NavLink}
                to="/"
              >
                Dashboard
              </Link>
              <Link component={NavLink} to="/sales">
                Sales
              </Link>
              <Typography>Details</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>
      </HeaderBox>

      <Grid
        sx={{ paddingTop: 5, paddingLeft: 5 }}
        container
        spacing={4}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <InfoBadge
            width={"250px"}
            name={"Status"}
            value={formatStatus(saleDetails.status)}
            main
          />
        </Grid>
        <Grid item>
          <InfoBadge
            width={"250px"}
            name={"PNR"}
            value={saleDetails.pnr}
            main
          />
        </Grid>
        <Grid item>
          <InfoBadge
            width={"250px"}
            name={"Request ID"}
            value={saleDetails.requestId!}
            link={`https://crm.privytrips.com/requests/edit/${saleDetails.requestId!}`} // Пример использования ссылки
            main
          />
        </Grid>
        <Grid item>
          <InfoBadge
            width={"250px"}
            name={"Client ID"}
            value={saleDetails.clientId!}
            link={`https://crm.privytrips.com/clients/edit/${saleDetails.clientId!}`}
            main
          />
        </Grid>
        <Grid item>
          <InfoBadge
            width={"250px"}
            name={"PQ ID"}
            value={saleDetails.pq_value!}
            link={`https://crm.privytrips.com/requests/edit/${saleDetails.requestId!}`} // Пример использования ссылки
            main
          />
        </Grid>
        <Grid item>
          <InfoBadge
            width={"250px"}
            name={"BO Request ID"}
            value={saleDetails.id}
            main
          />
        </Grid>
        {/*{agentDetails && (*/}
        {/*  <Grid item>*/}
        {/*    <InfoBadge*/}
        {/*      width={"250px"}*/}
        {/*      name={"Agent"}*/}
        {/*      value={`${agentDetails.firstName} ${agentDetails.lastName}`}*/}
        {/*      link={`https://pass.vipfares.com/admin/users/edit/${agentDetails.id}`}*/}
        {/*      main*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Box>
            <SaleTabs />
          </Box>
        </Grid>
      </Grid>
      <SendMailModal
        open={isSendMailModalOpen}
        onClose={handleCloseSendMailModal}
        requestId={saleDetails.requestId!}
        saleId={Number(id)}
      />
    </React.Fragment>
  );
}

export default InvoiceDetails;
